<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListStatementSRB"
  :defaultFilter="defaultFilter"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StatementSRB',

  data () {
    return {
      headers: [
        { value: 'date_create', text: this.$t('createDate'), sortable: true },
        { value: 'sailorFullName', text: this.$t('fullName'), sortable: false },
        { value: 'delivery', text: this.$t('delivery'), sortable: false },
        { value: 'is_payed', text: this.$t('payment'), sortable: true },
        { value: 'status_document', text: this.$t('status'), sortable: true },
        { value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: ({ id, sailor }) => ({ name: 'experience-statements-info', params: { id: sailor.id, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      defaultFilter: {
        page: '1',
        page_size: '10',
        status_document: ['42', '48', '87']
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementSRB,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListStatementSRB'])
  }
}
</script>
